import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import axios from 'axios'
import dayjs from 'dayjs'
import '@mdi/font/css/materialdesignicons.css'
import './styles/app.css'

import router from './router'
import store from './store'

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_HOST
})

axiosInstance.interceptors.request.use(function (config) {
    if (store.state.token) {
        config.headers.Authorization = `Bearer ${store.state.token}`
    }

    return config
}, function (error) {
    return Promise.reject(error)
})

axiosInstance.interceptors.response
    .use(response => response, error => {
        if (error.response && error.response.status === 401) {
            store.commit('LOGOUT')
            router.push('/login')
            Vue.db.apiCache.clear()
        }
        return Promise.reject(error)
    })

loadFonts()

const app = createApp(App)

app.use(dayjs)
app.use(vuetify)
app.use(store)
app.use(router)

// Global configs
app.config.globalProperties.$http = axiosInstance

export const useGlobals = () => app.config.globalProperties

app.mount('#app')