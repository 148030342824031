import { useGlobals } from '@/main'

export default {
    login (data) {
        const { $http } = useGlobals()
        return $http.post('/login', data)
    },

    logout () {
        const { $http } = useGlobals()
        return $http.post('/logout', {})
    },

    get () {
        const { $http } = useGlobals()
        return $http.get('/user')
    },

    update (data) {
        const { $http } = useGlobals()
        return $http.patch('/user', data)
    }
}
