import { useGlobals } from '@/main'

export default {
    liveUpdate(id, data) {
        const { $http } = useGlobals()
        return $http.patch(`/kiosk_locations/${id}/live_settings`, data)
    },

    startLaundry(id, data) {
        const { $http } = useGlobals()
        return $http.post(`/kiosk_locations/${id}/start_laundry`, data)
    }
}
