<template>
    <v-container class="flex h-full w-full">
        <div class="m-auto">
            <img class="Logo" :src="require('@/assets/ecLaundary_logo.png')">

            <div class="text-4xl text-bold text-center mb-8">
                Smart Laundry System
            </div>

            <login-form @submit="submit" />
        </div>
    </v-container>
</template>

<script>
import LoginForm from '@/components/forms/LoginForm'
import authService from '@/services/auth'

export default {
    components: {
        LoginForm,
    },

    methods: {
        submit(data) {
            authService.login(data).then(({ headers, data }) => {
                if (data.status === 'success') {
                    this.$store.commit('TOKEN', headers.authorization.split(' ')[1])
                    this.$store.commit('SET_USER', data.data)

                    this.$router.replace(this.$route.query.nextUrl || '/')
                    this.loading = false
                } else {
                    throw new Error(data.message)
                }
            }).catch(error => {
                this.error = true
                this.loading = false
                this.errorMsg = error.response.data.message
                console.log(error.message)
            })
        }
    },
}

</script>

<style lang="sass" scoped>
    .Logo
        max-width: 250px
        margin: 0 auto
</style>