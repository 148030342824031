import { createStore } from 'vuex'
import { get } from 'lodash'
import createPersistedState from 'vuex-persistedstate'

import modules from '@/store/modules'

const state = {
    title: 'Smart Laundry System',
    user: null,
    token: null,
    loading: false,

    pageError: null,
}

const getters = {
    userDisplayName(state) {
        return get(state, 'user.display_name', 'Unknown Namep')
    }
}

const mutations = {
    UPDATE_TITLE(state, title) {
        state.title = title
    },

    SET_USER(state, { id, display_name }) {
        state.user = {
            id, display_name
        }
    },

    TOKEN(state, token) {
        state.token = token
    },

    LOGOUT(state) {
        state.token = null
        state.user = null
    },

    SHOW_LOADING(state) {
        state.loading = true
    },

    HIDE_LOADING(state) {
        state.loading = false
    },

    SET_PAGE_ERROR(state, error) {
        state.pageError = error
    },

    RESET_PAGE_ERROR(state, error) {
        state.pageError = null
    },
}

const plugins = [
    createPersistedState({
        paths: ['token', 'user']
    })
]

const store = createStore({
    strict: process.env.NODE_ENV !== 'production',
    modules,
    state,
    getters,
    mutations,
    plugins,
})

export default store
