<template>
    <div class="text-4xl font-bold mb-10">
        Dashboard
    </div>
    <iframe
        :src="iframeURL()"
        frameborder="0"
        width="1280"
        height="900"
        allowtransparency
    />
</template>

<script>
import jwt from 'jsonwebtoken'

export default {
    methods: {
        iframeURL() {
            const payload = {
                resource: { dashboard: 427 },
                params: {},
                exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
            }
            const token = jwt.sign(payload, process.env.VUE_APP_METABASE_SECRET_KEY)
            return process.env.VUE_APP_METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true"
        },
    },
}
</script>
