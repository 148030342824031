<template>
    <v-dialog v-model="showModel" max-width="700">
        <v-card>
            <v-card-text>
                {{ title }}
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="error"
                    text
                    @click="cancel"
                >
                    No
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="submit"
                >
                    Yes
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    props: [
        'show',
        'title',
    ],

    data () {
        return {
            showModel: false,
        }
    },

    methods: {
        cancel () {
            this.showModel = false
        },

        submit () {
            this.$emit('confirm', { action: 'triggerMachine' })
            this.showModel = false
        },
    },

    watch: {
        show (val) {
            if (val) this.showModel = val
        },

        showModel (val) {
            if (!val) this.$emit('update:show', val)
        },
    }
}
</script>

