<template>
    <v-table>
        <thead>
            <tr>
                <td v-for="(title, i) in titles" :key="i">
                    {{ title }}
                </td>
            </tr>
        </thead>

        <tbody>
            <tr v-for="(device, i) in devices" :key="i">
                <td>{{ `${machineType(device.type)} ${device.label}` }}</td>
                <td>
                    <v-btn rounded="pill" :color="machineStatus(device.finished_at, device.active).color" flat>
                        {{ machineStatus(device.finished_at, device.active).title }}
                    </v-btn>
                </td>
                <td>
                    <v-btn icon="mdi-pencil" variant="text" @click="togglePriceModel(device.price, i)" />
                    {{ `$ ${device.price}` }}
                </td>
                <td>
                    <v-btn class="mr-4" :disabled="machineStatus(device.finished_at, device.active).title === 'OCCUPIED'" color="primary" @click="toggleConfirmModel(i)">
                        {{ 'TRIGGER' }}
                    </v-btn>
                    <v-btn :color="device.active ? 'error' : 'success'" @click="configUpdate('active', !device.active, i)">
                        {{ device.active ? 'DISABLE' : 'ENABLE' }}
                    </v-btn>
                </td>
            </tr>
        </tbody>
    </v-table>

    <price-model
        v-model:show="priceModel"
        v-model:price="price"
        @configUpdate="configUpdate"
    />
    <confirm-model
        v-model:show="confirmModel"
        title="Click the `Yes` button to confirm this action"
        @confirm="confirmAction"
    />
</template>

<script>
import { capitalize } from 'lodash'
import dayjs from 'dayjs'

//  Components
import PriceModel from '@/components/MachineManagement/PriceModel'
import ConfirmModel from '@/components/MachineManagement/ConfirmModel'

export default {
    props: {
        devices: Array,
    },

    components: {
        PriceModel,
        ConfirmModel,
    },

    data () {
        return {
            titles: [ 'Machine', 'Status', 'Price', 'Actions' ],

            priceModel: false,
            confirmModel: false,
            deviceIndex: 0,
            price: 0,
        }
    },

    methods: {
        machineType (type) {
            return capitalize(type)
        },

        machineStatus (finishedAt, active) {
            const currentTimestamp = dayjs().unix()
            if (!active) return { title: 'DISABLED', color: 'grey' }
            if (finishedAt > currentTimestamp) return { title: 'OCCUPIED', color: 'warning' }
            if (finishedAt < currentTimestamp) return { title: 'IDLE', color: 'info' }
        },

        togglePriceModel (price, device) {
            this.price = price
            this.deviceIndex = device
            this.modelWidth = 500
            this.priceModel = !this.priceModel
        },

        toggleConfirmModel (device) {
            this.deviceIndex = device
            this.confirmModel = !this.confirmModel
        },

        configUpdate (key, value = null, i = null) {
            var tempDevices = [ ...this.devices ]

            if (key === 'price') {
                tempDevices[this.deviceIndex][key] = this.price
                this.priceModel = false
            } else {
                tempDevices[i][key] = value
            }

            this.$emit('update', tempDevices)
        },

        confirmAction ({action, value}) {
            this[action](value)
        },

        triggerMachine (value = null) {
            if (!value) {
                this.$emit('activate', this.devices[this.deviceIndex].id)
                return
            }
            this.$emit('activate', value)
        },
    },
}
</script>
