<template>
    <v-dialog v-model="showModel" max-width="500">
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Update price
            </v-card-title>

            <v-card-text>
                <v-text-field
                    v-model="newPrice"
                    type="number"
                />
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    text
                    @click="save"
                >
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: [
        'show',
        'price',
    ],

    emits: [
        'update:show',
        'update:price',
    ],

    data () {
        return {
            showModel: false,
            newPrice: 0,
        }
    },

    methods: {
        save () {
            this.$emit('update:price', this.newPrice)
            this.$emit('configUpdate', 'price')
            this.showModel = false
        },
    },

    watch: {
        show (val) {
            if (val) this.showModel = val
        },

        showModel (val) {
            if (!val) this.$emit('update:show', val)
        },

        price (val) {
            this.newPrice = val
        },
    },
}
</script>

