<template>
    <div class="text-4xl font-bold mb-10">
        Machine Management
    </div>
    <div class="w-100">
        <v-autocomplete
            v-if="locations.length !== 0 || !loading"
            v-model="location"
            clearble
            label="Location"
            :items="locations"
            item-title="name"
            item-value="id"
        />
        <div v-else class="w-100 flex justify-center">
            <v-progress-circular indeterminate />
        </div>

        <machine-table
            v-if="devices.length !== 0"
            :devices="devices"
            @update="liveUpdate"
            @activate="triggerMachine"
        />
        <div v-else-if="locations.length !== 0 && devices.length === 0 && loading" class="w-100 flex justify-center">
            <v-progress-circular indeterminate />
        </div>
    </div>
</template>

<script>
import { head, isEmpty } from 'lodash'

import locationService from '@/services/location'
import kioskService from '@/services/kiosk'

import MachineTable from '@/components/MachineManagement/Table'

export default {
    components: {
        MachineTable,
    },

    data () {
        return {
            locations: [],
            location: null,

            devices: [],
            deviceConfigVersion: 0,
            deviceConfigUpdatedAt: 0,
            loading: false,
        }
    },

    watch: {
        async location (value) {
            if (!isEmpty(value) || value !== null) {
                this.getDevices()
                return
            }

            this.devices = []
        },
    },

    created () {
        this.reload()
    },

    methods: {
        async reload () {
            this.getLocations()
        },

        async getLocations () {
            this.loading = true
            const { data } = await locationService.getAll()
            this.locations = data.data
            this.loading = false
        },

        async getDevices () {
            this.loading = true
            const { data } = await locationService.get(this.location)
            this.devices = head(data.data).value
            this.deviceConfigVersion = head(data.data).version
            this.deviceConfigUpdatedAt = head(data.data).updated_at
            this.loading = false
        },

        async liveUpdate (data) {
            await kioskService.liveUpdate(this.location, {
                data: [
                    {
                        type: 'laundry-kiosk-app',
                        key: 'devices',
                        value: data,
                        version: this.deviceConfigVersion,
                        updated_at: this.deviceConfigUpdatedAt,
                    }
                ]
            })
            this.getDevices()
        },

        async triggerMachine (id) {
            await kioskService.startLaundry(this.location, { id })
            this.getDevices()
        },
    },
}
</script>
