import { useGlobals } from '@/main'

export default {
    getAll (withRelationships = []) {
        const { $http } = useGlobals()
        return $http.get('/my/locations', {
            params: {
                withRelationships: withRelationships
            }
        })
    },

    get (id) {
        const { $http } = useGlobals()
        return $http.get(`/kiosk_locations/${id}/live_settings`)
    },
}
