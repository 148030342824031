import { createWebHistory, createRouter } from 'vue-router'
import { template } from 'lodash'
import store from '@/store'

import Main from '@/pages/Main'
import Login from '@/pages/Login'

import Dashboard from '@/pages/Dashboard'
import MachineManagement from '@/pages/MachineManagement'

export const routes = [
    {
        path: '/',
        component: Main,
        children: [
            {
                path: '',
                meta: {
                    title: 'Dashboard',
                    requiresAuth: true,
                },
                name: 'Dashboard',
                icon: 'home',
                component: Dashboard,
            },
            {
                path: 'machine-management',
                meta: {
                    title: 'Machine Management',
                    requiresAuth: true,
                },
                name: 'Machine Management',
                icon: 'collage',
                component: MachineManagement,
            }
        ]
    },
    {
        path: '/login',
        component: Login,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        const compiled = template(to.meta.title)
        store.commit('UPDATE_TITLE', compiled({ data: to.params }))
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.state.token) {
            next({
                path: '/login',
                query: {
                    nextUrl: to.fullPath
                }
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router
