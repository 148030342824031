<template>
    <form class="LoginForm mx-auto">
        <v-text-field v-model="username" label="Username" :error-messages="errorMessages.username || ''" clearable />
        <v-text-field
            v-model="password"
            label="Password"
            :error-messages="errorMessages.password || ''"
            :type="sensitiveData ? 'password' : 'text'"
            :append-inner-icon="sensitiveData ? 'mdi-eye-off' : 'mdi-eye'"
            clearable
            @click:append-inner="sensitiveData = !sensitiveData"
        />
        <v-checkbox v-model="rememberMe" label="Remember me" class="text-black" />

        <v-btn class="w-100" color="primary" @click="submit">
            Sign In
        </v-btn>
    </form>
</template>

<script>
import { reduce } from 'lodash'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
    setup() {
        return { v$: useVuelidate() }
    },

    data() {
        return {
            username: '',
            password: '',
            rememberMe: false,

            sensitiveData: true,
            errorMessages: {},
        }
    },

    validations() {
        return {
            username: {
                required: helpers.withMessage('Username cannot be empty', required)
            },
            password: {
                required: helpers.withMessage('Password cannot be empty', required)
            },
        }
    },

    methods: {
        async submit() {
            this.errorMessages = {}
            const isValid = await this.v$.$validate()

            if (!isValid) {
                this.errorMessages = reduce(this.v$.$errors, (errors, error) => {
                    return { ...errors, [error.$property]: error.$message }
                }, {})
                return
            }

            this.$emit('submit', {
                username: this.username,
                password: this.password,
            })
        },
    }
}
</script>

<style lang="sass" scoped>
    .LoginForm
        max-width: 25rem
</style>
