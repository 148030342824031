<template>
    <div class="h-full w-full">
        <v-navigation-drawer permanent>

            <v-list>
                <v-list-item
                    v-for="(route, i) in routeList"
                    :key="i"
                    :prepend-icon="`mdi-${route.icon}`"
                    :title="route.name"
                    :active="$route.path === route.path"
                    active-color="primary"
                    link
                    @click="() => { $router.push(route.path) }"
                />
            </v-list>

            <template #append>
                <v-list>
                    <v-list-item
                        prepend-icon="mdi-logout"
                        title="Logout"
                        link
                        @click="logout"
                    />
                </v-list>
            </template>
        </v-navigation-drawer>

        <v-main>
            <v-container>
                <router-view />
            </v-container>
        </v-main>
    </div>
</template>

<script>
import authService from '@/services/auth'

export default {
    data () {
        return {
            routeList: [
                {
                    path: '/',
                    name: 'Dashboard',
                    icon: 'home',
                },
                {
                    path: '/machine-management',
                    name: 'Machine Management',
                    icon: 'collage',
                },
            ],
        }
    },

    methods: {
        async logout () {
            await authService.logout()
            this.$store.commit('TOKEN', null)
            this.$router.push({
                path: '/login',
            })
        },
    }
}
</script>
